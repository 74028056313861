footer {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #f1f1f1;
  box-shadow: 0px -3px 3px #c8c9c7;
  padding: 8px 0;
}

.footer-content {
  position: relative;
  height: 100%;
  align-self: center;
  width: 80%;
}

.footer-content p {
  margin: 0 25% 0 55%;
  width: fit-content;
  font-size: 0.9rem;
  text-align: center;
}

.version-info-div {
  position: absolute;
  text-align: right;
  right: 2%;
  font-size: 0.75rem;
  color: #399e5a;
}

.version-number {
  margin-top: 1px;
  margin-bottom: 1px;
}

@media only screen and (max-width: 1366px) {
  .footer-content {
    width: 70%;
  }

  .footer-content p {
    margin: 0 20% 0 50%;
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 960px) {
  .footer-content {
    width: 60%;
  }

  .footer-content p {
    margin-left: 2%;
    font-size: 0.8rem;
  }
}
